// Import Vue  from 'vue';
import Vue from 'vue/dist/vue.js';

// Import Bootstrap Vue
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

// Import SCSS styles
import '../scss/bootstrap4/styles.scss';

// Import lightgallery global
// import lgFullscreen from 'lg-fullscreen/dist/lg-fullscreen';
import lightgallery from 'lightgallery.js/dist/js/lightgallery';

// Import SVG Pan Zoom
import svgPanZoom from 'svg-pan-zoom';
Object.defineProperty(Vue.prototype, '$svgPanZoom', { value: svgPanZoom });

Vue.component('floorplan', {
  data: function() {
    return {};
  },
  props: ['title', 'id', 'data'],
  template: `
  <div>
    <object :id="id" type="image/svg+xml" :data="data"></object>
  </div>
  `,
  mounted: function() {
  }
});

const app = new Vue({
  el: '#app',
  data: function() {
    return {
      message: 'Hello Vue!',
      activePage: 'about',
      floorplansTabIndex: 0,
      slide: 0,
      sliding: null,
      navIsOpen: false
    };
  },
  methods: {
    gotoPage: function(target, e) {
      console.log('gotoPage: ', target);
      this.activePage = target;
      e.preventDefault();

      if (target === 'floorplans') {
        this.initFloorplan(this.floorplansTabIndex);
      }

      if (target === 'renderings') {
        Vue.nextTick(function() {
          //console.log(lightGallery)
          lightGallery(document.getElementById('lightgallery'), {
            thumbnail: false,
            animateThumb: false,
            counter: true,
            download: true,
            mode: 'lg-fade'
          });
        });
      }
    },
    changedTab: function(e) {
      console.log('changedTab', e);
      this.initFloorplan(e);
    },
    initFloorplan: function(index) {
      console.log('initFloorplan', index);
      setTimeout(() => {
        this.$svgPanZoom('#floorplan' + index, {
          zoomEnabled: true,
          controlIconsEnabled: true
        });
      }, 500);
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    mounted: function() {
    }
  }
});
